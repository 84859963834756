// src/App.tsx
import React from 'react';
import Lottie from "lottie-react";
import animationData from './animation.json';


// Defining more specific types for the styles
const styles = {
    body: {
        margin: '0' as const,
        padding: '0' as const,
        overflow: 'hidden' as const,
        height: '100vh' as const,
        width: '100vw' as const,
    },
    container: {
        textAlign: 'center' as const,
        padding: '50px' as const,
        backgroundColor: '#f3f3f3' as const,
        height: '100vh' as const,
        display: 'flex' as const,
        flexDirection: 'column' as const,
        justifyContent: 'center' as const,
        alignItems: 'center' as const,
    },
    title: {
        fontSize: '2rem' as const,
        color: '#d35400' as const,
    },
    subTitle: {
        fontSize: '1.5rem' as const,
        color: '#27ae60' as const,
    },
};

const App: React.FC = () => {
    // Applying styles to body element
    document.body.style.margin = styles.body.margin;
    document.body.style.padding = styles.body.padding;
    document.body.style.overflow = styles.body.overflow;
    document.body.style.height = styles.body.height;
    document.body.style.width = styles.body.width;

    return (
        <div style={styles.container}>
            <div>
                <Lottie animationData={animationData}/>
                <h1 style={styles.title}>Happy Birthday!</h1>
                <p style={styles.subTitle}>We're going to make great things together! I love you bro x</p>
            </div>
        </div>
    );
}

export default App;
